<template>
  <div>
    <v-card class="mb-4">
      <v-card-text>
        <v-row class="mt-3 mb-1">
          <v-col cols="12">
            <h2 class="mb-2">Trip variant</h2>
          </v-col>
        </v-row>
        <div v-if="is_loading_trips" class="inline-loader">
          <v-progress-circular color="primary" indeterminate></v-progress-circular>
        </div>
        <v-row v-else class="mt-1 mb-1" style="align-items: center">
          <v-col>
            <v-select
              v-model="selectedTrip"
              :items="formattedTripVariants"
              item-text="text"
              item-value="trip_id"
              label="Select Trip"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card v-if="!stops_initial">
      <v-card-text>
        <v-row class="mt-3 mb-1">
          <v-col cols="12">
            <h2 class="mb-2">Stops Preview</h2>
          </v-col>
        </v-row>

        <div v-if="is_loading_stops" class="inline-loader">
          <v-progress-circular color="primary" indeterminate></v-progress-circular>
        </div>

        <v-row v-else>
          <v-col cols="12">
            <v-list>
              <v-list-item-group>
                <v-list-item v-for="(stop, index) in tripStopsWithPreviewing" :key="index">
                  <v-list-item-content class="flex-nowrap">
                    <v-list-item-title> {{ stop.stop_id }} - {{ stop.stop_name }} </v-list-item-title>
                    <v-btn elevation="0" fab x-small @click="fullStopPreview(stop)">
                      <v-icon
                        v-if="!stop.isPreviewing"
                        class="d-flex justify-center align-center"
                        size="22"
                        color="primary"
                      >
                        {{ icons.mdiPlayCircle }}
                      </v-icon>
                      <v-icon v-else class="d-flex justify-center align-center" size="22" color="secondary">
                        {{ icons.mdiPlayCircle }}
                      </v-icon>
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { mdiPlayCircle } from "@mdi/js"
import store from "@/store"

export default {
  name: "RouteTrips",
  props: {
    route: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedTrip: null,
      routeId: this.route.route_id,
      is_loading_trips: true,
      is_loading_stops: true,
      stops_initial: true,
      isPreviewingAnotherStop: false,
    }
  },
  computed: {
    ...mapState({
      tripVariants: state => state.gtfs.trip_variants,
      tripStops: state => state.gtfs.trip_stops,
    }),

    formattedTripVariants() {
      return this.tripVariants.map(trip => {
        let text = `${trip.first_stop_name} - ${trip.last_stop_name}`
        if (trip.trip_headsign) {
          text += `, Headsign: ${trip.trip_headsign}`
        }

        return {
          ...trip,
          text,
        }
      })
    },

    tripStopsWithPreviewing() {
      return this.tripStops.map(stop => ({
        ...stop,
        isPreviewing: stop.isPreviewing || false,
      }))
    },
  },
  watch: {
    selectedTrip: {
      handler() {
        this.fetchStops()
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        await store
          .dispatch("getRouteTripsVariants", {
            route_id: this.routeId,
          })
          .then(() => {
            this.is_loading_trips = false
          })
      } catch (e) {
        console.error(e)
      }
    },

    async fetchStops() {
      this.stops_initial = false
      this.is_loading_stops = true

      try {
        await store
          .dispatch("getStopsByTripVariant", {
            trip_id: this.selectedTrip,
          })
          .then(() => {
            this.is_loading_stops = false
          })
      } catch (e) {
        console.error(e)
      }
    },

    fullStopPreview(stop) {
      if (this.isPreviewingAnotherStop) {
        return
      }

      const stopIndex = this.tripStops.findIndex(s => s.stop_id === stop.stop_id)
      if (stopIndex !== -1) {
        this.$set(this.tripStops, stopIndex, { ...stop, isPreviewing: true })
        this.isPreviewingAnotherStop = true

        store
          .dispatch("fullStopPreview", { stopId: stop.stop_id, routeId: this.routeId })
          .then(() => {
            this.$set(this.tripStops, stopIndex, { ...stop, isPreviewing: false })
            this.isPreviewingAnotherStop = false
          })
          .catch(() => {
            this.$set(this.tripStops, stopIndex, { ...stop, isPreviewing: false })
            this.isPreviewingAnotherStop = false
          })
      }
    },
  },
  setup() {
    return {
      icons: {
        mdiPlayCircle,
      },
    }
  },
}
</script>
